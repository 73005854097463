import React from "react";
import mainimg from "../assets/images/mainimg.jpg";
import stuimg from "../assets/images/WhatsApp Image 2024-01-04 at 02.04.40_eec472b1.jpg";
import study from "../assets/images/stude.jpg";
import aboutus from "../assets/images/about_us_img.jpg";
import com from "../assets/images/IMG_3124.png";
import front from "../assets/images/IMG_20231205_110023.jpg"
const Gallery = () => {
  return (
    <>
      <div className="bg-blue-gray-900  font-jost">
        <div className="text-black font-bold text-[35px] text-center bg-white ">
          <h1>OUR GALLERY</h1>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 place-content-center bg-[#ffffff] p-6 md:py-10 md:px-20">
          <div className="grid gap-4 place-content-center">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src={mainimg}
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src={stuimg}
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src={aboutus}
                alt=""
              />
            </div>
          </div>
          <div className="grid gap-4 place-content-center">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src={study}
                alt=""
              />
            </div>
            <div>
              <img className="h-auto max-w-full rounded-lg" src={com} alt="" />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src={front}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
