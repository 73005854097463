import React from "react";
import AboutImg from "../../assets/images/about_us_img.jpg";
import "./About.css";
const AboutUs = () => {
  return (
    <>
      
    </>
  );
};

export default AboutUs;
