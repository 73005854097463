import React, { useEffect, useRef } from "react";
import "./OurCourses.css";
import { gsap, Power3 } from "gsap";
const OurCourses = () => {
  let span1 = useRef(null);
  let span2 = useRef(null);
  let tl = new gsap.timeline();
  let ease = Power3.easeOut();
  useEffect(() => {
    tl.from([span1, span2], 1, {
      opacity: 0,
      y: -100,
      stagger: {
        amount: 0.4,
      },
      ease: ease,
    });
  });

  return (
    <>
      <section className="font-jost">
        <div className="relative  w-screen h-screen">
          <div className="bg-img w-screen h-screen"></div>
          <h1 className="absolute top-28 ml-5 text-[15vw] font-extrabold text-blue-500">
            <span ref={(el) => (span1 = el)} className="block">
              OUR
            </span>
            <span ref={(el) => (span2 = el)} className="block">
              COURSES
            </span>
          </h1>
        </div>
      </section>
    </>
  );
};

const Level1 = () => {
  return <section></section>;
};
export default OurCourses;
