import React from 'react'

const ContectUs = () => {
  return (
    <div>
      contectUs
    </div>
  )
}

export default ContectUs
